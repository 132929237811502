import httpClient  from '.'; 
const API_CONTROLLER = 'order-estimate/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    explain(data) { 
        let url = API_CONTROLLER + 'explain';
        return httpClient.post(url, data);
    },

    getCode(staffId) { 
        let url = API_CONTROLLER + 'get-code?staffId=' + staffId;
        return httpClient.get(url);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getAll(orderId) { 
        let url = API_CONTROLLER + 'get-all';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    submitApprove(data) { 
        let url = API_CONTROLLER + 'submit-approved';
        return httpClient.post(url, data);
    },

    requireEdit(data) { 
        let url = API_CONTROLLER + 'require-edit';
        return httpClient.post(url, data);
    },

    getForAdvancePayment(search) { 
        let url = API_CONTROLLER + 'get-for-advance-payment';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                code: search.code,
                name: search.name
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                status: search.status,
                customerId: search.customerId,
                orderId: search.orderId,
                noBooking: search.noBooking,
                orderCode: search.orderCode,
                code: search.code,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    getForApprove(id) { 
        let url = API_CONTROLLER + 'get-for-approve';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getForApproves(search) { 
        let url = API_CONTROLLER + 'get-for-approves';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                status: search.status,
                staffStatus: search.staffStatus,
                code: search.code
            }
        });
    },

    getDepositCont(search) { 
        let url = API_CONTROLLER + 'get-deposit-cont';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                status: search.status,
                customerId: search.customerId,
                keyword: search.keyword
            }
        });
    },

    updateDepositCont(id, backDepositDate) {
        let url = API_CONTROLLER + 'update-deposit-cont?id=' + id + "&backDepositDate=" + backDepositDate;
        return httpClient.post(url);
    }
}